/* eslint-disable no-undef */
import React, {useState, useEffect} from "react";
import Button from "../../../components/common/Button";
import BorderCard from "../../../components/common/BorderCard";
import Spinner from "../../../components/common/Spinner";
import {useWeb3React} from "@web3-react/core";
import {useFixedStaking, useMMProContract} from "../../../hooks/useContracts";
import {
  getFixedStakingAddress,
  getMMProAddress,
} from "../../../utils/getAddress";
import {wei2eth} from "../../../utils/common";
import fromExponential from "from-exponential";
import {StakingInfo} from "../../../components/staking/StakingInfo";
import './index.css'

const NOLOADING = 0;
const APPROVELOADING = 1;
const STAKELOADING = 2;


export default function FixedLayout(props) {
  const {active, account} = useWeb3React();
  const fixedStakeContract = useFixedStaking();
  const MMProContract = useMMProContract();
  const [initLoading, setInitLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [stakedByUser, setStakedByUser] = useState(0);
  const [stakingOptionState, setStakingOptionState] = useState(0);
  const [fixedStakingOption, setFixedStakingOption] = useState(Array);
  const [allowance, setAllowance] = useState(0);
  const [stakedByUserList, setStakedByUserList] = useState([])

  useEffect(() => {
    const initValues = async () => {
      setInitLoading(true);
      await initFixedStake();
      setInitLoading(false);
    };
    if (active) {
      initValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const initFixedStake = async () => {
    const tmpFixedStakeOptinos = await fixedStakeContract.methods
      .getStakeOptions(getMMProAddress())
      .call();

    const tmpBalance = await MMProContract.methods.balanceOf(account).call();
    const stakedByUserArray = await fixedStakeContract.methods
      .getUserStakes(account)
      .call();
    const allowance = await MMProContract.methods
      .allowance(account, getFixedStakingAddress())
      .call();

    let sumOfStaked = 0;

    for (var i = 0; i < stakedByUserArray.length; i++) {
      if (stakedByUserArray[i].stakeToken === getMMProAddress()) {
        sumOfStaked += parseInt(stakedByUserArray[i].amount);
      }
    }

    setAmount(0);
    setAllowance(allowance);
    setBalance(tmpBalance);
    setStakedByUser(sumOfStaked);
    setStakedByUserList(stakedByUserArray)
    setFixedStakingOption(tmpFixedStakeOptinos);
  };

  const approve = async () => {
    setActionLoading(APPROVELOADING);
    const amount2eth = fromExponential(10 ** 10 * 10 ** 18);
    await MMProContract.methods
      .approve(getFixedStakingAddress(), amount2eth)
      .send({from: account});
    await initFixedStake();
    setActionLoading(NOLOADING);
  };

  const stake = async () => {
    const arg = fromExponential(amount * 10 ** 18);
    try {
      const allowance = await MMProContract.methods
        .allowance(account, getFixedStakingAddress())
        .call();
      console.log(allowance)
      console.log(arg)
      if (allowance < fromExponential(10 ** 10 * 10 ** 18)) {
        await approve();
      }
    } catch (e) {
      console.log(e);
    }
    setActionLoading(STAKELOADING);
    try {
      await fixedStakeContract.methods
        .stake(getMMProAddress(), arg, stakingOptionState)
        .send({from: account});
    } catch (err) {
      if (err.code !== 4001) {
        console.error(err);
      }
      console.error(err);
    }
    await initFixedStake();
    setActionLoading(NOLOADING);
  };

  const onStakeOptionSelectChanged = (e) => {
    setStakingOptionState(e.target.value);
  };

  return (
    <div>
      {!active && <div className="text-center">Connect Wallet</div>}
      {initLoading && <div className="text-center">Loading...</div>}
      {!initLoading && active && (
        <div className="staking-cards-container px-4">
          {/*<BorderCard noLine title={`Available: ${parseFloat(wei2eth(balance)).toFixed(2)} MMPRO`}*/}
          {/*            className={' stake-configurator'}>*/}
          {/*  <div*/}
          {/*    className="stake-configurator-flex flex items-center flex-wrap justify-between w-auto al border-card-container rounded-lg p-4 card-bg border-white border-2 border-solid">*/}
          {/*    <div className="flex flex-wrap items-center my-2">*/}
          {/*      <span className={'staking-duration-text mr-2 text-2xl'}>Staking Duration:</span>*/}
          {/*      <div*/}
          {/*        className="staking-time-select rounded-md flex-grow border-2 border-primary p-1 mr-2 flex justify-between items-center">*/}
          {/*        <select*/}
          {/*          value={stakingOptionState}*/}
          {/*          onChange={onStakeOptionSelectChanged}*/}
          {/*          className="staking-input text-white font-extrabold text-2xl flex-shrink w-full bg-transparent focus:outline-none px-2"*/}
          {/*        >*/}
          {/*          {fixedStakingOption.filter(option => Number(option.periodInDays) < 999).map((option, index) => {*/}
          {/*            return(*/}
          {/*              <option key={option.periodInDays} value={index}>*/}
          {/*                {parseFloat(option.periodInDays)} days APY: {(((option.bonusInPercentage / 100)/option.periodInDays) * 360).toFixed(1)}%*/}
          {/*              </option>*/}
          {/*            )*/}
          {/*          })}*/}
          {/*        </select>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="flex flex-shrink items-center my-2">*/}
          {/*      <div className="rounded-md border-2 border-primary p-1 mr-2 flex justify-between items-center">*/}
          {/*        <input*/}
          {/*          type="number"*/}
          {/*          placeholder="MMPRO To Stake"*/}
          {/*          value={amount || ''}*/}
          {/*          onChange={(e) => setAmount(e.target.value)}*/}
          {/*          className="staking-input text-white font-extrabold text-2xl flex-shrink w-full bg-transparent focus:outline-none px-2"*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*      <Button*/}
          {/*        onClick={() => stake()}*/}
          {/*        disabled={Number(amount) > parseFloat(wei2eth(balance)).toFixed(2) && wei2eth(allowance) > Number(amount)}*/}
          {/*        bgColor={*/}
          {/*          (Number(amount) > parseFloat(wei2eth(balance)).toFixed(2) && wei2eth(allowance) > Number(amount)) ? 'gray-500': 'primary'}*/}
          {/*        className="flex flex-row items-center justify-center"*/}
          {/*      >*/}
          {/*        <img src="/images/locked.svg" width="25" alt=""/>*/}
          {/*        <span className="w-28">*/}
          {/*          {wei2eth(allowance) < Number(amount)*/}
          {/*            ? "Approve"*/}
          {/*            : "Stake"}*/}
          {/*        </span>{" "}*/}
          {/*        {*/}
          {/*          (actionLoading === APPROVELOADING ||*/}
          {/*            actionLoading === STAKELOADING) &&*/}
          {/*          <Spinner size={20} color={'#FFFFFF'}/>*/}
          {/*        }*/}
          {/*      </Button>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</BorderCard>*/}

          <BorderCard title={"Staked"} noLine className={' staked-vault'}>
              <StakingInfo total={parseFloat(wei2eth(stakedByUser)).toFixed(2)} stakedByUserList={stakedByUserList}
                           updateFixedStake={initFixedStake}/>
          </BorderCard>

        </div>
      )}
    </div>
  );
}
