import React, {useState, useEffect} from "react";
import {useWeb3React} from "@web3-react/core";
import {useFixedStaking} from "../../../hooks/useContracts";
import {wei2eth} from "../../../utils/common";
import {getMMProAddress} from "../../../utils/getAddress";
import {StakingElement} from "../StakingElement";
import './index.css'

const calcTimeToWithdraw = (stakeEndTime, currentTime) => {
  const diffSecs = stakeEndTime - currentTime

  if (diffSecs <= 0) {
    return ''
  }
  const diff_in_days = Math.floor(diffSecs / 3600 / 24).toFixed(0);
  const diff_in_hours = Math.floor((diffSecs % (3600 * 24)) / 3600).toFixed(
    0
  );
  const diff_in_mins = Math.floor(
    ((diffSecs % (3600 * 24)) % 3600) / 60
  ).toFixed(0);
  const diff_in_secs = Math.floor((diffSecs % (3600 * 24)) % 3600) % 60;

  if(diff_in_days > 0){
    return `${diff_in_days} Days, ${diff_in_hours > 0 ? `${diff_in_hours} hours`: ''}`
  }

  return `${diff_in_hours > 0 ? `${diff_in_hours}:` : ''}${(diff_in_mins < 10 && diff_in_mins > 0) ? '0': ''}${diff_in_mins > 0 ? `${diff_in_mins}:` : ''}${diff_in_secs < 10 ? `0${diff_in_secs}`: `${diff_in_secs}`}`;

};

export const StakingInfo = ({total, stakedByUserList, updateFixedStake}) => {
  const {account} = useWeb3React();
  const [stakeList, setStakeList] = useState([])
  const [stakeLoading, setStakeLoading] = useState([])
  const fixedStakeContract = useFixedStaking();
  const [currentTime, setCurrentTime] = useState(Math.floor(new Date().getTime() / 1000));


  async function withdrawEarnings(stakeId) {

    stakeLoading[stakeId] = true
    setStakeLoading(stakeLoading)
    try {
      const stakeInfo = await fixedStakeContract.methods.usersStake(account, stakeId).call();
      if (stakeInfo.end < Date.now() / 1000) {
        await fixedStakeContract.methods.withdraw(getMMProAddress(), stakeId).send({from: account});
      }
    } catch (err) {
      if (err.code !== 4001) {
        console.error(err);
      }
      console.error(err);
    }

    stakeLoading[stakeId] = false
    setStakeLoading(stakeLoading)
    await updateFixedStake()
  }


  useEffect(() => {
    if (!stakedByUserList) {
      return
    }
    const _stakeList = []
    setStakeLoading(Array.from({length: 5}, (v, i) => false))

    for (let stakeInd = 0; stakeInd < stakedByUserList.length; stakeInd++) {
      let stake = {
        amount: parseFloat(wei2eth(stakedByUserList[stakeInd].amount)).toFixed(2),
        rewards: parseFloat(wei2eth(stakedByUserList[stakeInd].rewards)).toFixed(2),
        stakeEnd: parseInt(stakedByUserList[stakeInd].end),
        withdrawFunc: async () => {
          return await withdrawEarnings(stakeInd)
        }
      }
      _stakeList.push(stake)

    }
    setStakeList(_stakeList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakedByUserList])

  useEffect(() => {
    setTimeout(() => {
      setCurrentTime(Math.floor(new Date().getTime() / 1000));
    }, 1000);
  }, [currentTime]);

  let totalRewards = 0;
  stakeList.forEach(stake => {
    totalRewards += +stake.rewards
  })

  return (
    <div className={'staking-info flex flex-col'}>
      <div className="self-start text-white mb-4">
        Total: <b>{total}</b> MMPRO (reward: {totalRewards} MMPRO)
      </div>
      <div className={`stakes-flex`}>
        {stakeList.map((stakeInfo, stakeInd) => (<StakingElement
          amount={stakeInfo.amount}
          rewards={stakeInfo.rewards}
          timeLeft={calcTimeToWithdraw(stakeInfo.stakeEnd, currentTime)}
          withdrawFunc={stakeInfo.withdrawFunc}
          loading={stakeLoading[stakeInd]}
          locked={currentTime < stakeInfo.stakeEnd}
        />))}
      </div>
    </div>
  )

}