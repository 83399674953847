import Spinner from "../../common/Spinner";
import React, {useCallback} from "react";
import Button from "../../common/Button";
import './index.css'


export const StakingElement = ({amount, rewards, timeLeft, withdrawFunc, loading, locked}) => {

  const handleClick = useCallback(async () => {
    if (!loading) {
      await withdrawFunc()
    }
  }, [withdrawFunc, loading])


  return (
    <div className={`staking-element border-card-container rounded-lg p-6 px-3 card-bg border-white border-2 border-solid ${locked ? '' : 'unlocked'}`}>

      <div className={'staking-element-timer flex justify-between mb-3'}>
        <div className={'flex-col mr-3'}>
          <div>
            Stake
          </div>
          <div className={'text-2xl'}>
            {amount}
          </div>
        </div>
        <div className={'flex-col mr-3'}>
          <div>
            Reward
          </div>
          <div className={'text-2xl'}>
            {rewards}
          </div>
        </div>
      </div>
      <Button
        onClick={handleClick}
        className="unstake-button flex flex-row items-center w-48 justify-center"
        disabled={loading}
        bgColor={locked ? 'gray-500': 'primary'}
      >
        {loading ? (
          <Spinner size={25} color={'#FFFFFF'}/>
        ) : (
          <>
            <img
              src={locked ? "/images/locked.svg" : "/images/unlocked.svg"}
              width="25"
              alt=""
            />
            <span className={`w-64`} style={locked ? {fontWeight: "bolder", fontSize: 16}: {}}> {locked ? timeLeft: 'Unstake & Claim'}</span>
          </>
        )}
      </Button>
      {/*{timeLeft !== '' &&*/}
      {/*<div className={'time-remaining'}>*/}
      {/*  {timeLeft}*/}
      {/*</div>*/}
      {/*}*/}
    </div>
  )
}